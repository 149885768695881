const { PLAID_ENV } = require('../env');

// @TODO:  https://jira.plaid.com/browse/SITE-4341

/* eslint-disable @typescript-eslint/naming-convention */
// This is a list of pages that previously used the ios layout.
// In the legacy website the ios template did not include any analytics
// So this ensures analytics are not applied to these pages
export const AnalyticsBlockList = new Set([
  '/cra/en/legal/privacy-policy',
  '/cra/en/legal/consumer-services-agreement',
  '/en/legal/ios',
  '/en/legal/ios/about',
  '/en/legal/ios/eu',
  '/es/legal/ios',
  '/es/legal/ios/about',
  '/es/legal/ios/eu',
  '/fr/legal/ios',
  '/fr/legal/ios/about',
  '/fr/legal/ios/eu',
  '/legal/ios',
  '/legal/ios/about',
  '/legal/ios/eu',
]);

export const MenuItemTypes = Object.freeze({
  dropdown: 'dropdown',
  link: 'link',
  heading: 'heading',
});

export const ButtonTypes = Object.freeze({
  PRIMARY_CTA: 'Primary Cta',
  SECONDARY_CTA: 'Secondary Cta',
  HYPERLINK: 'Hyperlink',
  SECONDARY: 'Secondary',
  DEFAULT: 'Default',
  FULL_WIDTH: 'Full Width',
});

export const ButtonVersion = Object.freeze({
  LEGACY_BUTTON: 'button',
  TARGET: 'targetButton',
});

export const TargetButtonTargets = Object.freeze({
  MODAL: 'Modal',
  DEFAULT: 'Default',
  NEW_TAB: 'New Tab',
});

export const Contentful = Object.freeze({
  CONTENT_TYPE_ID: 'sys.contentType.sys.id',
  EMBEDDED_ENTRY_CONTENT_TYPE_ID: 'data.target.sys.contentType.sys.id',
  EMBEDDED_ASSET_CONTENT_TYPE_ID: 'data.target.sys.type',
});

export const ContentfulTemplates = Object.freeze({
  BLOG_OVERVIEW: 'overviewTemplate',
  BLOG_POST: 'blogPostTemplate',
  LANDING_PAGE: 'landingPage',
  CUSTOMER_STORY: 'customerStory',
  CUSTOMER_STORIES: 'customerStories',
  PARTNER_DIRECTORY: 'partnerDirectory',
  PARTNER_PAGE: 'partnerPage',
  PRODUCT_PAGE: 'productPage',
  CAREERS_PAGE: 'careersPage',
  USE_CASE: 'useCase',
  EVENT: 'event',
  TOPIC: 'topic',
  ARTICLE: 'article',
  HUB: 'hub',
  PLAID_FORUM_TEMPLATE: 'plaidForumTemplate',
  PAGE: 'template',
});

export const TemplatesToSubdirectories = Object.freeze({
  [ContentfulTemplates.BLOG_OVERVIEW]: 'blog',
  [ContentfulTemplates.BLOG_POST]: 'blog',
  [ContentfulTemplates.LANDING_PAGE]: '', // empty string represents root
  [ContentfulTemplates.CUSTOMER_STORIES]: 'customer-stories',
  [ContentfulTemplates.CUSTOMER_STORY]: 'customer-stories',
  [ContentfulTemplates.PARTNER_DIRECTORY]: '',
  [ContentfulTemplates.PRODUCT_PAGE]: 'products',
  [ContentfulTemplates.CAREERS_PAGE]: '', // empty string represents root
  [ContentfulTemplates.USE_CASE]: 'use-cases',
  [ContentfulTemplates.EVENT]: 'events',
  [ContentfulTemplates.TOPIC]: 'resources',
  [ContentfulTemplates.HUB]: 'resources',
  [ContentfulTemplates.ARTICLE]: 'resources',
  [ContentfulTemplates.PLAID_FORUM_TEMPLATE]: 'plaid-forum',
});

export const StaticTemplates = Object.freeze({
  WHAT_IS_PLAID: 'whatIsPlaid',
  WHY_PLAID_IS_INVOLVED: 'whyPlaidIsInvolved',
  CONTACT_THANKS: 'contactThanks',
  CONTACT: 'contact',
  DEVELOPER_POLICY: 'developerPolicy',
  DISCOVER_APPS: 'discoverApps',
  GLOBAL: 'global',
  HOW_IT_WORKS: 'howItWorks',
  HOW_WE_HANDLE_DATA: 'howWeHandleData',
  OPEN_BANKING: 'openBanking',
  PARTNERS: 'partners',
  SECURITY: 'security',
  TROUBLE_CONNECTING: 'troubleConnecting',
  CUSTOMER_STORIES: {
    ALTO: 'alto',
    ASTRA: 'astra',
    BRIGIT: 'brigit',
    CARVANA: 'carvana',
    DROP: 'drop',
    METAL: 'metal',
    PLACID_EXPRESS: 'placid-express',
    PROSPER: 'prosper',
    QAPITAL: 'qapital',
    SHIFT: 'shift',
    SOLO: 'solo',
    VARO: 'varo',
    WAVE: 'wave',
    OVERVIEW_PAGE: 'overviewPage',
  },
});

export const CustomerStoriesComponentTypes = Object.freeze({
  PAGE_HEADER: 'customerStoriesPageHeader',
  QUOTE_WITH_IMAGE: 'quoteWithImage',
  SOCIAL_PROOF: 'mvpSocialProofComponent',
  FORM_CTA: 'formCta',
});

export const CmsComponents = Object.freeze({
  ACCORDION: 'accordion',
  ACCOUNT_TABLE_SECTION: 'accountTableSection',
  ANCHOR: 'anchor',
  ASSET: 'asset',
  BACKGROUND_CONTAINER: 'backgroundContainer',
  BLOG_POST_EXCERPT: 'blogPostExcerpt',
  BUTTON: 'button2',
  BUTTON_GROUP: 'buttonGroup',
  CARD: 'card',
  CARDS_SECTION: 'cardsSection',
  CAROUSEL: 'carousel2',
  CODE_SNIPPET: 'codeSnippet',
  CONTACT_FORM: 'sectionContactForm',
  CONTENT_BLOCK: 'mvpContentBlock',
  CTA_BANNER: 'ctaBanner',
  CTA_BLOCK_WITH_BUTTONS: 'ctaBlockWithButtons',
  CTA_BLOCK_WITH_IMAGE: 'ctaBlockWithImage',
  CUSTOMER_STORY_PAGE_HEADER: 'customerStoryPageHeader',
  DECORATIVE_TEXT: 'decorativeText',
  DESCRIPTION_ROW: 'descriptionRow',
  DIAGRAM_SECTION: 'diagramSection',
  EMBEDDED_VIDEO_CARD: 'embeddedVideoCard',
  EVENT_LIST: 'eventList',
  FEATURES_SECTION: 'mvpFeaturesSection',
  FOOTER: 'footer',
  FORM_CTA: 'formCta',
  GATED_CONTENT: 'gatedContent',
  GRID_COLUMN: 'gridColumn',
  GRID_CONTAINER: 'gridContainer',
  HERO: 'hero2',
  HERO_LARGE_IMAGE: 'heroLargeImage',
  HYPERLINK: 'hyperlink',
  IMAGE: 'image',
  INFOCHART_SECTION: 'infochartSection',
  INFOGRAPHIC_SECTION: 'infographicSection',
  INTERACTIVE_DATA: 'interactiveData',
  INTRO_SECTION: 'introSection',
  ITEMS: 'items',
  LEAD_FORM: 'leadForm',
  LEFT_RIGHT_SECTION: 'leftRightSection',
  LEGACY_CAROUSEL: 'carousel',
  LOGOS: 'logos',
  MARKDOWN: 'markdown',
  MARKETO_FORM: 'marketoForm',
  PAGE_HEADER: 'mvpPageHeader',
  PAGE_HEADER_WITH_FORM: 'pageHeaderWithForm',
  PARTNERS_LIST: 'partnerCardsDiverse',
  PARTNER_DIRECTORY_PAGE_HEADER: 'partnerDirectoryPageHeader',
  PARTNER_PAGE_HEADER: 'partnerPageHeader',
  PAYWALL: 'paywall',
  PILL: 'pill',
  PRODUCTS_PAGE_HEADER: 'productsPageHeader',
  PRODUCT_DATA_SECTION: 'productData',
  QUOTE: 'quote',
  QUOTE_CMS: 'mvpQuoteComponent',
  QUOTE_WITH_IMAGE: 'quoteWithImage',
  RAW_HTML: 'rawHtml',
  RICH_TEXT_COMPONENT: 'richTextComponent',
  RICH_TEXT_HIGHLIGHTS_SECTION: 'richTextHighlightsSection',
  SECTION: 'section',
  SECTIONAL_HEADER: 'sectionalHeader',
  SECTION_ASSET: 'sectionAsset',
  SHOWCASE: 'mvpShowcaseComponent',
  SIMPLE_STATS_SECTION: 'simpleStatsSection',
  SOCIAL_PROOF: 'mvpSocialProofComponent',
  SPACER: 'spacer',
  STAT_BLOCK: 'statBlock',
  STATIC_DATA: 'staticData',
  SUPPORTING_TEXT: 'supportingText',
  TAG: 'tag',
  TILE_GROUP: 'tileGroup',
  TIMELINE: 'timeline',
  VIDEO_EMBED: 'videoEmbed',
  VIDEO_WITH_FALLBACK_IMAGE: 'videoWithFallbackImage', // TODO: deprecate this - https://plaidinc.atlassian.net/browse/SITE-4852
});

export const Locales = Object.freeze({
  EN_US: 'en-us',
  EN_EU: 'en-eu',
  EN_GB: 'en-gb',
  EN_CA: 'en-ca',
});

// map old contentful faux locale values to the new iso locale codes
// deprecated and should not be used
export const LocaleMap = Object.freeze({
  es: 'es-es',
  eu: 'en-eu',
  fr: 'fr-fr',
  uk: 'en-gb',
  us: 'en-us',
  ca: 'en-ca',
});

export const ContentfulLocales = new Set(['en-US', 'en-EU', 'en-GB']);

export const RichTextComponents = Object.freeze({
  BUTTON: 'button',
  IMAGE: 'image',
  RAW_HTML: 'rawHtml',
  CODE_SNIPPET: 'codeSnippet',
  BLOCKQUOTE: 'blockquote',
  CTA_BUTTON_WITH_TRACKING: 'ctaButtonWithTracking',
  TOOLTIP: 'tooltip',
  QUOTE_WITH_IMAGE: 'quoteWithImage',
});

export const RichTextAssets = Object.freeze({
  ASSET: 'Asset',
});

export const HubTypes = Object.freeze({
  RESOURCES: 'Resources',
});

export const TileTypes = Object.freeze({
  PEOPLE_SPOTLIGHT: 'peopleSpotlightEntry',
});

export const BLOG_ENTRIES_PER_PAGE = 15;
export const MAX_EXCERPT_WORD_COUNT = 28;
export const MAX_EXCERPT_WORD_COUNT_FEATURED = 46;

export const STATIC_IMAGE_QUALITY = 75;
export const CMS_IMAGE_QUALITY = 70;
export const LAZY_OFFSET = 400;

export const isProduction =
  PLAID_ENV === 'production' || PLAID_ENV === 'PRODUCTIONNEXT';
export const isDevelopment = PLAID_ENV === 'development';
export const isTesting =
  PLAID_ENV === 'testing' || PLAID_ENV === 'AUTOBUILDNEXT';
export const isPreview = true;

// Careers constants
export const PROFILES = {
  get default() {
    return this.profile7;
  },
  profile3: {
    name: 'Jake Stern',
    title: 'Account Executive',
    bio: 'With a degree in economics and a stint on the 2016 presidential campaign under his belt, Jake Stern joined the Plaid sales team in 2017.',
    image: '/assets/img/people/plaid-people-jake-landscape@2x.png',
    url: 'https://blog.plaid.com/plaid-people-jake-stern/',
  },
  profile7: {
    name: 'Jennifer Wu',
    title: 'Software Engineer',
    bio: 'Software engineer Jennifer Wu is one of a growing set of remote team members empowered to develop their careers from wherever they choose to live.',
    image: '/assets/img/people/plaid-people-jennifer-landscape@2x.jpg',
    url: 'https://blog.plaid.com/plaid-people-jennifer-wu/',
  },
};

export const JOB_DATA = {
  'Account Management': {
    description:
      'We partner with customers to help them build and scale the best applications on top of Plaid.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  'Business Development': {
    description:
      'We build the global partnerships within financial services that enable the supply side of Plaid’s platform.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  'Business Operations': {
    description:
      'We ensure cross-functional coordination and increase efficiency across Plaid to help us achieve our Company goals.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Communications: {
    description:
      'We share stories about how Plaid and the fintech services we power make money easier for people around the world. ',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  'Corporate Development': {
    description:
      'We evaluate and facilitate product partnerships, investments, and acquisitions to expand Plaid’s products and business.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  'Professional Services': {
    description:
      'We work with customers to help them build and scale the best applications on top of Plaid.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  Design: {
    description:
      'We bring ideas to life, serve as the voice of our users, and create a meaningful and unified Plaid experience.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Engineering: {
    description:
      'We build simple yet innovative consumer products and developer APIs that shape how everybody interacts with money and the financial system.',
    roles: [],
    profiles: {
      byLocation: {
        'New York': 'profile3',
        'San Francisco': 'profile7',
        'Salt Lake City': 'profile3',
        Remote: 'profile7',
      },
      locationDependent: true,
    },
  },
  'EPDS Operations': {
    description:
      'We accelerate and simplify the delivery of products through systems, processes, and program management.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Finance: {
    description:
      'We enable Plaid’s sustainable growth by providing financial insights and raising capital to invest in our business.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  'Financial Access': {
    description:
      'We work with the industry to enable consumers to connect to their favorite services and lead healthier financial lives.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  'Financial Institution Partnerships': {
    description:
      'We work with the industry to enable consumers to connect to their favorite services and lead healthier financial lives.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  Legal: {
    description:
      'We analyze complex and novel legal issues for Plaid to enable innovation.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  'New Product GTM & Commercial': {
    description:
      'We work with prospective customers to help them navigate and evaluate our solutions so that any business can become a fintech.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  Marketing: {
    description:
      'We reach, engage, and inspire innovators to build with Plaid.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Partnerships: {
    description:
      'We build long-term relationships to accelerate the growth and reach of Plaid’s products across the financial ecosystem.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  People: {
    description: 'We make Plaid the highlight of your career.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Product: {
    description:
      'We evaluate what to pursue, craft strategies to maximize impact, and help teams deliver market success.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  'Product Support': {
    description:
      'We support the next generation of financial services by investigating, escalating, and fixing the issues affecting our customers and our consumers.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Recruiting: {
    description:
      'We build the company by hiring each and every future employee into Plaid.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  Risk: {
    description:
      "We're responsible for enabling the business by managing risks and maintaining controls that ensure trust in the platform.",
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  Sales: {
    description:
      'We work with prospective customers to help them navigate and evaluate our solutions so that any business can become a fintech.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  Security: {
    description:
      "We enable Plaid to quickly build safe and secure products while ensuring that Plaid's users, data, and infrastructure remains protected.",
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
  University: {
    description:
      'We engage with university students and promote early career opportunities across the company.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile7',
      },
    },
  },
  'Workplace Experience': {
    description:
      'We build and maintain an inspiring and inclusive employee experience at Plaid.',
    roles: [],
    profiles: {
      byLocation: {
        all: 'profile3',
      },
    },
  },
};

export const JOB_LOCATIONS = [
  'Amsterdam',
  'Canada',
  'London',
  'Netherlands',
  'New York',
  'Raleigh-Durham',
  'San Francisco',
  'United Kingdom',
  'United States',
];

export const CAREERS_PATH = '/careers';

export const CareersUrlSlugs = {
  ALL_DEPARTMENTS: 'all-departments',
  ALL_LOCATIONS: 'all-locations',
  REMOTE: 'remote',
  ROLE_QUERY: '?role=',
};

// Resources constants
export const RESOURCES_PATH = '/resources';

export enum FormTypes {
  Legacy = 'LEGACY_FORM',
  Gated = 'GATED_CONTENT_FORM',
  CMS = 'CMS',
}

export const PAYWALL_SELECTOR = '.paywall-noop';
export const PaywallRenderOptions = Object.freeze({
  MODAL: 'Open component in modal',
});

// TODO: modal and stay on screen will need to be wired up at some point in the Button Group
// alternatively they can be removed with an application based on some context
export const targetMap = Object.freeze({
  Default: '_self',
  Modal: '_self',
  'New Tab': '_blank',
  'Stay On Screen': '_self',
  'new window': '_blank',
  'same window': '_self',
  'New tab': '_blank',
  'Same tab': '_self',
});

export const LOCALES = Object.freeze({
  EN_US: 'en-US',
  EN_EU: 'en-EU',
  EN_GB: 'en-GB',
});

export const CmsThemes = Object.freeze({
  WEB2: 'web 2.0',
  WEB3: 'web 3.0',
});
